<template>
  <div>
    <el-carousel height="150px" style="padding-top: 5px">
        <el-carousel-item><img :src="require('@/assets/banner/banner1.jpg')"></el-carousel-item>
        <el-carousel-item><img :src="require('@/assets/banner/banner2.jpg')"></el-carousel-item>
        <el-carousel-item><img :src="require('@/assets/banner/banner3.jpg')"></el-carousel-item>
    </el-carousel>
<div class="title-box">
  <div class="audio-title">
    <p>课程内容</p>
  </div>
</div>
    <div class="grade-container">
      <!--      <BookItem v-for="item in books" :key="item.index" :BookItem = item ></BookItem>-->
      <div class="grade-detail" v-for="(item,index) in grades" :key="index" :BookItem=item >
        <!--      <div class="book-detail" @click="goToDetail()">-->
        <div class="grade-item" >
<!--          {{item.gradeName}}-->
<!--          <el-button :style="{'background-color':item.gradeColor}" type="primary" class = "grade-name-button" @click="goToGradeBooks(item,index)">{{ item.gradeName }}</el-button>-->
          <img :src=item.gradeCover @click="goToGradeBooks(item,index)">
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import {gradeList} from '/src/data/data2.js'
// import BookItem from "@/components/BookItem";

export default {
  name: 'BookList',
  props: {},
  data() {
    return {
      grades: gradeList,
    }
  },
  methods:{
    goToGradeBooks(item,index){
      console.log("跳转前")
      console.log(item.index)
      this.$router.push({name: 'GradeBooks', query: {gradeIndex: index}})
    }
  },
  created() {
    console.log(this.grades);
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.grade-container {
  display: flex;
  /*align-content: flex-start;*/
  flex-flow: column wrap;
  justify-content: flex-start;
  align-items: center;
  margin: 0px 15px 0 15px;
  background-color: #dadada;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-inline: 15px;
  border-radius: 5px;
}
.title-box{
  padding: 12px 12px;
  font-family: PingFangSC-Semibold,serif;
  display: flex;
}
.grade-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 8px;
}

.grade-detail {
  flex: 0 0 100%;
  width: 100%;
  margin-bottom: 2px;
}
.audio-title {
  font-weight: 700;
  display: flex;
  border-bottom: .05rem solid #ddd;
  padding: 0 3px;
  font-size: 23px;
  align-items: center;
}
img {
  border-radius: 5px;
}
</style>
